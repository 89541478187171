<script setup lang="ts">
import Swiper from 'swiper';
import 'swiper/swiper-bundle.css';
import { Ref, onMounted, ref } from 'vue';
import products from '@/jsons/products.json'
import { store } from '@/store/store';
import { useRouter } from 'vue-router';

const Store = store();
const router = useRouter();

const mySwiper = ref();
const progressAnim: Ref<boolean> = ref(true);

const autoPlayDelay: number = 6000;
let autoPlayInterval: number | undefined = undefined;
let progressDelay: number | undefined = undefined;
let navTimeOut: number | undefined = undefined;

const initSwiper = (): void => {
    mySwiper.value = new Swiper('.product-slider.swiper-container', {
        // Configure Swiper options here
        loop: true,
        slidesPerView: 1,
        allowTouchMove: false,
        speed: 800,
    });
    mySwiper.value.init();
}
const switchSlider = (move: boolean): void => {
    move ? mySwiper.value.slideNext() : mySwiper.value.slidePrev();
    progressAnim.value = false;
    window.clearTimeout(progressDelay);
    progressDelay = window.setTimeout(() => {
        progressAnim.value = true;
    }, 1)
    enableAutoPlay();
}
const enableAutoPlay = (): void => {
    window.clearInterval(autoPlayInterval);
    autoPlayInterval = window.setInterval(() => {
        switchSlider(true)
    }, autoPlayDelay)
}

const navigatePage = (route: string): void => {
    Store.setIsNavigate(true);

    window.clearTimeout(navTimeOut)
    navTimeOut = window.setTimeout(() => {
        router.push(`/project/${route}`);
    }, Store.getNavLoaderTime + 200)
}

onMounted(() => {
    initSwiper();
    enableAutoPlay();
})
</script>

<template>
    <div class="product-slider swiper-container" @mouseenter="Store.setActiveMouse(true)"
        @mouseleave="Store.setActiveMouse(false)">
        <div class="swiper-wrapper">
            <div class="swiper-slide pointer" v-for="(item, index) in products" :key="index" @click="navigatePage(item.route)">
                <div class="box">
                    <img :src="require('@/assets/' + item.video)" class="bg-video abs-img" alt="Background gif" />
                    <div class="content">
                        <p class="title">{{ item.name }}</p>
                        <div class="img-cont">
                            <div class="paralax-img" :style="`transform: translateY(-${Store.getScrollY * ((index + 1) / 9)}px); border: 4px solid ${item.frameColor}`" v-for="(image, index) in item.images.slice(0, 3)" :key="index" data-scroll
                                :data-scroll-speed="index + 1">
                                <img draggable="false" v-lazy="{ src: require('@/assets/' + image), loading: require('@/assets/loading.gif')}" class="abs-img" alt="Game image">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <button class="swiper-prev center" @click="switchSlider(false)" @mouseenter="Store.setActiveMouse(false)"
            @mouseleave="Store.setActiveMouse(true)">
            <svg width="34" height="28" viewBox="0 0 34 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M1.39343 12.0659C0.881368 12.5786 0.59375 13.2736 0.59375 13.9982C0.59375 14.7228 0.881368 15.4178 1.39343 15.9305L11.7038 26.2463C12.2168 26.7593 12.9125 27.0475 13.638 27.0475C14.3634 27.0475 15.0591 26.7593 15.5721 26.2463C16.085 25.7334 16.3732 25.0377 16.3732 24.3122C16.3732 23.5868 16.085 22.8911 15.5721 22.3781L9.92832 16.7325L30.9557 16.7325C31.6809 16.7325 32.3764 16.4445 32.8892 15.9317C33.402 15.4189 33.69 14.7234 33.69 13.9982C33.69 13.273 33.402 12.5775 32.8892 12.0647C32.3764 11.5519 31.6809 11.2638 30.9557 11.2638L9.92832 11.2638L15.5721 5.62005C15.8261 5.36606 16.0275 5.06453 16.165 4.73267C16.3025 4.40082 16.3732 4.04513 16.3732 3.68594C16.3732 3.32674 16.3025 2.97106 16.165 2.6392C16.0275 2.30734 15.8261 2.00581 15.5721 1.75182C15.3181 1.49783 15.0165 1.29635 14.6847 1.15889C14.3528 1.02143 13.9972 0.950684 13.638 0.950684C13.2788 0.950684 12.9231 1.02143 12.5912 1.15889C12.2594 1.29635 11.9578 1.49783 11.7038 1.75182L1.39343 12.0659Z"
                    fill="black" />
            </svg>

        </button>
        <button class="swiper-next center" @click="switchSlider(true)" @mouseenter="Store.setActiveMouse(false)"
            @mouseleave="Store.setActiveMouse(true)">
            <svg width="34" height="28" viewBox="0 0 34 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M32.8904 15.9323C33.4024 15.4196 33.6901 14.7246 33.6901 14C33.6901 13.2754 33.4024 12.5804 32.8904 12.0677L22.58 1.75182C22.067 1.23886 21.3713 0.950684 20.6459 0.950684C19.9204 0.950684 19.2247 1.23886 18.7117 1.75182C18.1988 2.26478 17.9106 2.9605 17.9106 3.68593C17.9106 4.41137 18.1988 5.10709 18.7117 5.62005L24.3555 11.2656L3.32815 11.2656C2.60295 11.2656 1.90745 11.5537 1.39466 12.0665C0.881863 12.5793 0.593777 13.2748 0.593777 14C0.593777 14.7252 0.881863 15.4207 1.39466 15.9335C1.90745 16.4463 2.60295 16.7344 3.32815 16.7344L24.3555 16.7344L18.7117 22.3781C18.4577 22.6321 18.2563 22.9336 18.1188 23.2655C17.9814 23.5974 17.9106 23.953 17.9106 24.3122C17.9106 24.6714 17.9814 25.0271 18.1188 25.359C18.2563 25.6908 18.4577 25.9924 18.7117 26.2464C18.9657 26.5003 19.2673 26.7018 19.5991 26.8393C19.931 26.9767 20.2867 27.0475 20.6459 27.0475C21.0051 27.0475 21.3607 26.9767 21.6926 26.8393C22.0244 26.7018 22.326 26.5003 22.58 26.2464L32.8904 15.9323Z"
                    fill="black" />
            </svg>

        </button>
        <div class="progress-cont">
            <div class="progress" :class="{ 'is-animated': progressAnim }"></div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
@import "../styles/variables.scss";
@import "../styles/mixins.scss";
@import "../styles/responsive.scss";
@import "../styles/animations.scss";

.product-slider {
    overflow: hidden;
    position: relative;

    .progress-cont {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 15px;
        background-color: $darkGray;
        z-index: 2;

        .progress {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            background-color: $yellow;

            &.is-animated {
                animation: progress-fill linear infinite 6s;
            }
        }
    }

    .swiper-prev,
    .swiper-next {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 3;
        width: 70px;
        height: 70px;
        border-radius: 50%;
        border: 4px solid $black;
        background-color: $yellow;
        cursor: pointer;
        opacity: 0.5;
        transition: 0.6s;

        @include hover() {
            opacity: 1;
        }

        @include responsive(tablet) {
            width: 50px;
            height: 50px;

            svg {
                width: 20px;
                height: 20px;
            }
        }
    }

    .swiper-prev {
        left: 34px;

        @include responsive(tablet) {
            left: 12px;
        }
    }

    .swiper-next {
        right: 34px;

        @include responsive(tablet) {
            right: 12px;
        }
    }

    .swiper-wrapper {
        height: calc(100vh - 110px);
    }

    .swiper-slide {
        height: 100%;

        .box {
            position: relative;
            height: 100%;

            .content {
                position: absolute;
                top: 20px;
                left: 50%;
                width: 100%;
                height: 100%;
                transform: translateX(-50%);
                z-index: 2;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;

                @media (max-height: 968px) {
                    transform: translateX(-50%) scale(0.8);
                }

                .img-cont {
                    position: relative;

                    .paralax-img {
                        position: relative;
                        width: 425px;
                        height: 239px;
                        left: 0;
                        z-index: 3;
                        // border: 4px solid $black;

                        @include responsive(tablet) {
                            width: 352px;
                            height: 189px;
                        }

                        @include responsive(mobile-large) {
                            width: 252px;
                            height: 129px;
                            margin-bottom: 15px;
                        }

                        &:nth-child(2) {
                            left: 270px;
                            top: -50px;
                            z-index: 2;

                            @include responsive(tablet) {
                                left: 110px;
                                top: -40px;
                            }

                            @include responsive(mobile-large) {
                                left: 0;
                                top: 0px;
                            }
                        }

                        &:nth-child(3) {
                            top: -160px;
                            z-index: 1;
                            left: -100px;

                            @include responsive(tablet) {
                                top: -80px;
                            }

                            @include responsive(mobile-large) {
                                display: none;
                            }
                        }
                    }
                }

                .title {
                    font-size: 48px;
                    color: $white;
                    margin-bottom: 20px;

                    @include responsive(mobile-large) {
                        font-size: 32px;
                    }
                }
            }

            .bg-video {
                filter: brightness(0.4) blur(3px);
                z-index: 1;
            }
        }
    }
}</style>