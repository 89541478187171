<script setup lang="ts">
import { ComputedRef, Ref, computed, onMounted, ref } from 'vue';
import { store } from '@/store/store'
import headerGEO from '@/jsons/headerGEO.json'
import headerENG from '@/jsons/headerENG.json'
import { scrollSection } from "@/modules/scrollTo"
import { useRoute, useRouter } from 'vue-router';

const Store = store();
const router = useRouter();
const route = useRoute();

const langSelectOpen: Ref<boolean> = ref(false);
const modalIsOpen: Ref<boolean> = ref(false);
const modalCloseAnim: Ref<boolean> = ref(false);
const isScrolled: ComputedRef<boolean> = computed(() => Store.getIsScroll)

const modalCloseTime: number = 580;
const scrollTime: number = 1500;
let modalCloseTimeOut: number | undefined = undefined;
let navTimeOut: number | undefined = undefined;
let scrollTimeOut: number | undefined = undefined;
let onloadTimeOut: number | undefined = undefined;

const currentLangCode: ComputedRef<string | null> = computed(() => Store.getCurrentLang);
const secondLangCode: ComputedRef<string | undefined> = computed(() => Store.getLangCodes.find((item) => item.toUpperCase() !== currentLangCode.value))
const menuItems: ComputedRef<{ text: string }[]> = computed(() => Store.getCurrentLang === 'GE' ? headerGEO : headerENG)

// CUSTOM FUNCTIONS
const closeModal = (): void => {
    modalCloseAnim.value = true;
    window.clearTimeout(modalCloseTimeOut);
    modalCloseTimeOut = window.setTimeout(() => {
        modalIsOpen.value = false;
        modalCloseAnim.value = false;
    }, modalCloseTime)
}
const changeLang = (): void => {
    Store.changeCurrentLang(secondLangCode.value?.toUpperCase() as string)
    langSelectOpen.value = false;
}
const menuClick = (index: number, close: boolean): void => {
    let target;
    if (index === 0) {
        target = document.querySelector('#productSection')
    } else if (index === 1) {
        target = document.querySelector('#newsSection')
    } else if (index === 2) {
        target = document.querySelector('#aboutSection')
    } else if (index === 3) {
        target = document.querySelector('#supportSection')
    }
    if (close) closeModal();
    if (target) {
        router.push({ query: { section: target.id } })
        scrollSection(target)
    } else {
        if (index === 0) {
            target = 'productSection';
        } else if (index === 1) {
            target = 'newsSection';
        } else if (index === 2) {
            target = 'aboutSection';
        } else if (index === 3) {
            target = 'supportSection';
        }
        navigateHome(target);
    }
}
const navigateHome = (section: string | null | undefined): void => {
    Store.setIsNavigate(true);
    window.clearTimeout(navTimeOut)
    navTimeOut = window.setTimeout(() => {
        router.push({ path: '/home', query: { section: section } });
    }, Store.getNavLoaderTime + 200)

    if (section) {
        window.clearTimeout(scrollTimeOut);
        scrollTimeOut = window.setTimeout(() => {
            setDefSection();
        }, Store.getNavLoaderTime + scrollTime);
    }
}

const setDefSection = (): void => {
    const target = document.querySelector(`#${route.query.section}`);
    scrollSection(target)
}
onMounted(() => {
    window.clearTimeout(onloadTimeOut);
    onloadTimeOut = window.setTimeout(() => {
        setDefSection();
    }, Store.getNavLoaderTime + scrollTime);
})
</script>

<template>
    <header :class="{ scrolled: !isScrolled }">
        <div class="cont container">
            <div class="left-side pointer" @click="navigateHome(null)">
                <img draggable="false" src="@/assets/jortsoft-logo.png" class="long" alt="Jortsoft long logo" />
                <img draggable="false" src="@/assets/jortsoft_text_logo.png" class="short" alt="Jortsoft text logo" />

            </div>
            <div class="right-side">
                <div class="menus">
                    <div v-for="(item, index) in menuItems" :key="index" class="menu-item pointer"
                        @click="menuClick(index, false)">
                        <p>{{ item.text }}</p>
                        <div class="line"></div>
                    </div>
                </div>
                <div class="lang-select pointer" :class="{ open: langSelectOpen }">
                    <div class="top-cont" @click="langSelectOpen = !langSelectOpen">
                        <p class="uppercase">{{ currentLangCode }}</p>
                        <div class="icon center">
                            <svg width="13" height="10" viewBox="0 0 13 10" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M0.874992 9.875L12.125 9.875C12.2389 9.87465 12.3505 9.84323 12.4479 9.78414C12.5453 9.72505 12.6247 9.64053 12.6776 9.53966C12.7305 9.43879 12.7549 9.3254 12.7482 9.2117C12.7414 9.098 12.7038 8.98829 12.6394 8.89438L7.01437 0.76938C6.78124 0.432505 6.21999 0.432505 5.98624 0.76938L0.361242 8.89438C0.296133 8.98809 0.257951 9.09786 0.250846 9.21175C0.24374 9.32564 0.267982 9.4393 0.320939 9.54038C0.373895 9.64146 0.45354 9.7261 0.55122 9.78509C0.6489 9.84409 0.76088 9.87518 0.874992 9.875Z"
                                    fill="black" />
                            </svg>
                        </div>
                    </div>
                    <div class="bottom-cont" @click="changeLang()">
                        <p class="uppercase">{{ secondLangCode }}</p>
                    </div>
                </div>
                <button class="center burger" @click="modalIsOpen = true">
                    <svg width="29" height="21" viewBox="0 0 29 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.375 1.75H27.625M1.375 10.5H27.625M1.375 19.25H27.625" stroke="black"
                            stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </button>
            </div>
        </div>
        <div class="modal" v-if="modalIsOpen" :class="{ close: modalCloseAnim }">
            <div class="outdide-mask" @click="closeModal()"></div>
            <div class="popup">
                <button class="center close" @click="closeModal()">
                    <svg width="30" height="30" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z"
                            fill="black" />
                    </svg>
                </button>
                <div class="menu-cont">
                    <div class="menus">
                        <div v-for="(item, index) in menuItems" :key="index" class="menu-item pointer"
                            @click="menuClick(index, true)">
                            <p>{{ item.text }}</p>
                            <div class="line"></div>
                        </div>
                        <div class="lang-select pointer mobile" :class="{ open: langSelectOpen }">
                            <div class="top-cont" @click="langSelectOpen = !langSelectOpen">
                                <p>{{ currentLangCode }}</p>
                                <div class="icon center">
                                    <svg width="13" height="10" viewBox="0 0 13 10" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M0.874992 9.875L12.125 9.875C12.2389 9.87465 12.3505 9.84323 12.4479 9.78414C12.5453 9.72505 12.6247 9.64053 12.6776 9.53966C12.7305 9.43879 12.7549 9.3254 12.7482 9.2117C12.7414 9.098 12.7038 8.98829 12.6394 8.89438L7.01437 0.76938C6.78124 0.432505 6.21999 0.432505 5.98624 0.76938L0.361242 8.89438C0.296133 8.98809 0.257951 9.09786 0.250846 9.21175C0.24374 9.32564 0.267982 9.4393 0.320939 9.54038C0.373895 9.64146 0.45354 9.7261 0.55122 9.78509C0.6489 9.84409 0.76088 9.87518 0.874992 9.875Z"
                                            fill="black" />
                                    </svg>
                                </div>
                            </div>
                            <div class="bottom-cont" @click="changeLang()">
                                <p>{{ secondLangCode }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
</template>

<style lang="scss" scoped>
@import "../styles/variables.scss";
@import "../styles/mixins.scss";
@import "../styles/responsive.scss";
@import "../styles/animations.scss";

// VARIABLES
$defaultHeaderHeight: 110px;
$scrolledHeaderHeight: 100px;
$mobileHeaderHeight: 60px;
$mobileScrolledHeaderHeight: 80px;
$headerShadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

header {
    position: fixed;
    z-index: 9;
    top: 0;
    left: 0;
    width: 100%;
    height: $defaultHeaderHeight;
    background-color: $yellow;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    transition: 0.4s;

    .lang-select {
        position: relative;

        @include responsive(tablet) {
            display: none;

            &.mobile {
                display: flex;
                justify-content: flex-end;
            }
        }

        &.open {
            .bottom-cont {
                cursor: pointer;
                pointer-events: all;
                opacity: 1;
                transform: translateY(0px);
            }

            .icon {
                transform: rotate(0deg);
            }
        }

        .icon {
            transform: rotate(180deg);
            margin-left: 3px;
            transition: 0.4s;
        }

        p {
            font-size: 20px;
        }

        .top-cont {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .bottom-cont {
            position: absolute;
            bottom: -25px;
            left: 0;
            width: 100%;
            transition: 0.4s;
            transform: translateY(-5px);
            opacity: 0;
            cursor: none;
            pointer-events: none;
        }
    }

    .modal {
        position: fixed;
        width: 100%;
        height: 100dvh;
        background-color: rgba(0, 0, 0, 0.6);
        top: 0;
        left: 0;
        animation: enter-fade 0.6s;

        .outdide-mask {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: transparent;
        }

        &.close {
            animation: exit-fade 0.6s;

            .popup {
                animation: exit-right 0.6s;
            }
        }

        .popup {
            position: absolute;
            top: 0;
            right: 0;
            width: 70%;
            height: 100%;
            background-color: $yellow;
            animation: enter-right 0.6s;

            .menu-cont {
                height: 100%;
                display: flex;
                justify-content: flex-end;
                padding-top: 100px;

                .menus {
                    text-align: right;
                    padding-right: 17px;
                    font-size: 28px;

                    .menu-item {
                        margin-bottom: 13px;
                        position: relative;

                        &.active {
                            .line {
                                width: 100%;
                            }
                        }

                        .line {
                            position: absolute;
                            bottom: 0;
                            right: 0;
                            width: 0%;
                            height: 2px;
                            background-color: $black;
                            transition: 0.6s;
                        }
                    }
                }
            }

            .close {
                position: absolute;
                top: 20px;
                left: 20px;
                background: transparent;
            }
        }
    }

    @include responsive(tablet) {
        height: $mobileScrolledHeaderHeight;
        box-shadow: $headerShadow;
    }

    box-shadow: $headerShadow;

    &.scrolled {
        height: $scrolledHeaderHeight;

        @include responsive(tablet) {
            height: $mobileScrolledHeaderHeight;
        }

        .long {
            width: 40px !important;
        }
    }

    .cont {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .left-side {
            position: relative;
            .short {
                display: none;
                width: 150px;

                @include responsive(tablet) {
                    display: flex;
                }
            }

            .long {
                width: 50px;
                transition: 0.4s;

                @include responsive(tablet) {
                    display: none;
                }
            }

            .small {
                width: 85px;
                position: absolute;
                left: 0;
                top: 0;

                @include responsive(tablet) {
                    display: none;
                }
            }
        }

        .right-side {
            margin-left: 38px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;

            @include responsive(tablet) {
                justify-content: flex-end;
            }

            .burger {
                display: none;

                @include responsive(tablet) {
                    background: transparent;
                    display: flex;
                }
            }

            .menus {
                display: flex;
                justify-content: flex-start;
                align-items: center;

                @include responsive(tablet) {
                    display: none;
                }

                .menu-item {
                    margin-right: 24px;
                    position: relative;

                    @include hover() {
                        .line {
                            width: 100%;
                        }
                    }

                    .line {
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        width: 0%;
                        height: 2px;
                        background-color: $black;
                        transition: 0.6s;
                    }

                    p {
                        font-size: 24px;
                    }
                }
            }
        }
    }
}
</style>