<script setup lang="ts">
import { Ref, ref } from "vue";
import ProductSlider from "@/components/ProductSlider.vue";
import Footer from "@/components/Footer.vue";
import NewsSlider from "@/components/NewsSlider.vue";
import About from "@/components/About.vue";
import Banner from "@/components/Banner.vue";
import VidePopup from "@/components/VideoPopup.vue"
import { useHead } from '@vueuse/head'
import { store } from "@/store/store";
import Support from "@/components/Support.vue";

const Store = store();

useHead({
  title: "Jortsoft",
  meta: [
    {
      name: "description",
      content: "Jortsoft home page"
    },
    {
      property: "og:url",
      content: Store.getDomain
    },
    {
      property: "og:type",
      content: "website"
    },
    {
      property: "og:title",
      content: "Jortsoft home page"
    },
    {
      property: "og:description",
      content: "Jortsoft home page"
    },
    {
      property: "og:image",
      content: "https://opengraph.b-cdn.net/production/documents/e75df412-75d0-4e3b-8af8-d947120743b6.jpg?token=YD4SwTdd2E04mG9TgECpm4VrgAZB4VBEPf9T9_clrOA&height=675&width=1200&expires=33240107303"
    },
  ]
})

const pageCont: Ref<HTMLDivElement | null> = ref(null);
</script>

<template>
  <div class="home" ref="pageCont">
    <ProductSlider id="productSection" />
    <NewsSlider id="newsSection" />
    <About id="aboutSection" />
    <Banner />
    <Support id="supportSection"  />
    <Footer />
  </div>
</template>

<style lang="scss" scoped>
@import "../styles/variables.scss";
@import "../styles/responsive.scss";

.home {
  padding-top: 110px;

  @include responsive(tablet) {
    padding-top: 80px;
  }
}
</style>
